<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Call Management</v-toolbar-title>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn
          class="mr-1"
          to="/call-management/create"
          outlined
          color="toolbarIcon"
          text
          :loading="loader"
        >
          <v-icon left dark>mdi-plus</v-icon> New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <!-- start of filters -->
        <v-row dense align-content="space-around">
          <v-col cols="4">
            <v-text-field
              height="10"
              flat
              outlined
              dense
              label="Region"
              v-model="territory.descript"
              @click="openRegionModal"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              multiple
              cache-items
              search-input=""
              v-model="options.SlpCodes"
              :items="salesEmployees"
              item-text="SlpName"
              item-value="SlpCode"
              label="Sales Rep"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="options.CardCodes"
              :items="bpMasterData"
              item-text="CardName"
              item-value="CardCode"
              label="Outlet"
              multiple
              cache-items
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="options.Status"
              :items="Status"
              item-text="name"
              item-value="value"
              label="Status"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <DatePicker
              @date="setDate"
              :myDate="options.Recontact"
              :title="`Date`"
            ></DatePicker>
          </v-col>

          <!-- <v-col cols="4">
            <TimePicker
              @time="setTime"
              :id="1"
              :timing="`start`"
              :title="`Time`"
            ></TimePicker>
          </v-col> -->

          <v-col cols="4">
            <v-btn @click="getCalls()" color="accent">Filter</v-btn>
          </v-col>
        </v-row>
        <!-- end of filters -->

        <!-- start of datatable -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="calls"
              :search="search"
              dense
            >
              <!-- Status template -->
              <template v-slot:item.Status="props">
                <span v-if="props.item.Status == 'D'" class="blue--text"
                  >Due</span
                >
                <span v-if="props.item.Status == 'O'" class="green--text"
                  >Open</span
                >
                <span v-if="props.item.Status == 'C'" class="yellow--text"
                  >Closed</span
                >
                <span v-if="props.item.Status == 'A'" class="red--text"
                  >Abandoned</span
                >
              </template>

              <!-- end -->

              <!-- Approval  Status template -->
              <template v-slot:item.AprovalStatus="props">
                <span v-if="props.item.AprovalStatus == 'P'">Pending</span>
                <span v-if="props.item.AprovalStatus == 'A'">Approved</span>
              </template>
              <!-- end -->

              <!-- outlet template -->
              <template v-slot:item.outlet?.CardName="props">
                <v-btn
                  color="flatButton"
                  text
                  :to="`/outlet/${props.item?.outlet?.id}`"
                  >{{ props.item.outlet?.CardName }}</v-btn
                >
              </template>
              <!-- end -->

              <!-- sales employee template -->
              <template v-slot:item.employees?.SlpName="props">
                <v-btn
                  color="flatButton"
                  text
                  :to="`/sales/employee/${props.item.employees?.id}`"
                  >{{ props.item.employees?.SlpName }}</v-btn
                >
              </template>
              <!-- end -->

              <!-- Opened time template -->
              <!-- <template v-slot:item.OpenedTime="props">
                <span v-if="props.item.OpenedTime == null">Yet</span>
                <span v-else>{{ props.item.OpenedTime }}</span>
              </template> -->
              <!-- end -->

              <!-- Close time template -->
              <!-- <template v-slot:item.CloseTime="props">
                <span v-if="props.item.CloseTime == null">Yet</span>
                <span v-else>{{ props.item.CloseTime }}</span>
              </template> -->
              <!-- end -->

              <!-- Close time template -->
              <!-- <template v-slot:item.Repeat="props">
                <span v-if="props.item.Repeat == 'N'">Never</span>
                <span v-if="props.item.Repeat == 'D'">Daily</span>
                <span v-if="props.item.Repeat == 'W'">Weekly</span>
                <span v-if="props.item.Repeat == 'F'">Fortnightly</span>
                <span v-if="props.item.Repeat == 'M'">Monthly</span>
                <span v-if="props.item.Repeat == 'A'">Annually</span>
              </template> -->
              <!-- end -->
              <template v-slot:item.id="props">
                <v-btn :to="`/call-management/edit/${props.item.id}`" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  v-if="props.item.Status == 'D'"
                  @click="openCall(props.item.id)"
                  color="green"
                  variant="primary"
                  small
                  icon
                >
                  <v-icon>mdi-lock-open-variant-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="
                    props.item.Status == 'O' ||
                    props.item.Status == 'D' ||
                    props.item.Status == 'A'
                  "
                  @click="closeCall(props.item.id)"
                  color="red"
                  variant="primary"
                  icon
                  small
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- end of data table -->

        <!-- start of territory dialog -->
        <v-dialog v-model="dialog" width="650px">
          <v-card>
            <v-toolbar color="accent" dark dense>
              <v-toolbar-title>Select Territory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <!-- territories -->
                <v-col cols="12">
                  <v-treeview
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    :selection-type="selectionType"
                    open-all
                    return-object
                    item-text="descript"
                    item-key="id"
                    :items="territories"
                    item-children="children_recursive"
                    :search="search"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{
                          item.descript
                        }}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
                <!-- end -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of territory dialog -->
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("territory", ["territories"]),
  },
  data() {
    return {
      loading: false,
      loader: false,
      selectionType: "leaf",
      search: null,
      employeesFilterValue: null,
      salesEmployees: [],
      calls: [],
      bpMasterData: [],
      trueValue: true,
      territory: {},
      dialog: false,
      modal2: false,
      time: null,
      Status: [
        { name: "Queued", value: "D" },
        { name: "Open", value: "O" },
        { name: "Closed", value: "C" },
        { name: "Abandoned", value: "A" },
      ],
      headers: [
        { text: "Outlet", value: "outlet.CardName" },
        { text: "Date", value: "CallDate" },
        { text: "Start Time", value: "CallTime" },
        { text: "End Time", value: "CallEndTime" },
        {
          text: "Sales Employee",
          value: "employees.SlpName",
        },
        { text: "Status", value: "Status" },
        // { text: "Opened At", value: "OpenedTime" },
        // { text: "Closed At", value: "CloseTime" },
        { text: "Repeat", value: "Repeat" },
        { text: "Actions", value: "id" },

        // { text: "Approval Status", value: "AprovalStatus" },
      ],
      options: { CardCodes: [], SlpCodes: [], Status: null, search: null },
    };
  },
  methods: {
    getCalls() {
      const self = this;
      this.loading = true;
      let url = `/call?`;

      if (this.options.Status) {
        url += `${url.includes("?") ? "&" : "?"}Status=${this.options.Status}`;
      }
      if (this.options.SlpCodes.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }SlpCode=${this.options.SlpCodes.map(
          (p) => `${encodeURIComponent(p)}`
        ).join(",")}`;
      }
      if (this.options.CardCodes.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }CardCode=${this.options.CardCodes.map(
          (p) => `${encodeURIComponent(p)}`
        ).join(",")}`;
      }
      // if (this.call.search) {
      //   url += `${url.includes("?") ? "&" : "?"}search=${this.call.search}`;
      // }
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.calls = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openRegionModal() {
      this.dialog = true;
    },
    selectItem(data) {
      this.territory = data;
      this.dialog = false;
    },
    setDate(date) {
      this.calls.Recontact = date;
    },
    employeesFilter(item) {
      // If this filter has no value we just skip the entire filter.
      if (!this.SlpCode) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return item === this.SlpCode;
    },
    openCall(id) {
      var userResponse = confirm("Are you sure you want to Open the Call ?");
      if (userResponse === true) {
        const url = `/call_action/${id}`;
        const self = this;
        let data = { type: "O" };
        self.$store
          .dispatch("put", { url, data })
          .then(() => {
            this.getCalls();
          })
          .catch((err) => {
            console.log(err, "err");

            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
    closeCall(id) {
      var userResponse = confirm("Are you sure you want to Close the Call ?");
      if (userResponse === true) {
        const url = `/call_action/${id}`;
        const self = this;
        let data = { type: "C" };
        self.$store
          .dispatch("put", { url, data })
          .then(() => {
            this.getCalls();
          })
          .catch((err) => {
            console.log(err, "err");

            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCalls();
    this.getEmployees();
    this.getBpMaster();
  },
};
</script>

<style scoped>
.row {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>